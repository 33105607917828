import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../context/context";
import { Dropdown } from "react-bootstrap";
import PageSideMenu from "./pageSideMenu";
import CountryModal from "../components/Modal/countryModal";
import ROOT_URL from "../services/baseURL";
import common from "../services/common";
import authAxios from "../services/authAxios";
import Moment from "react-moment";
import NotFound, { NotificationNotFound } from "../components/common/notFount";
import { boolean } from "yup";
import Translator from "../components/header/translator2";

export default function Header() {
    // const { isLogin, reset, setModalCountry, initialData, profileData, setProfileData, setLanguage } = useContext(UserContext);
    const { isLogin, reset, setModalCountry, initialData, profileData, setProfileData, cartnotification, setCartnotification } = useContext(UserContext);
    const [menu, setMenu] = useState(true);
    const [width, setWidth] = useState();
    // const { isLogin, reset, setModalCountry, initialData, setProfileData } = useContext(UserContext);
    const [notificationList, setNotification] = useState(new Array());
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const [menuDrp, setMenuDrp] = useState(false);
    const navigate = useNavigate();
    // const cityIdforMatch= initialData?.city?._id
    const calendarStrings = {
        lastDay: "[Yesterday at] LT",
        sameDay: "[Today at] LT",
        nextDay: "[Tomorrow at] LT",
        lastWeek: "[last] dddd [at] LT",
        nextWeek: "dddd [at] LT",
        sameElse: "L",
    };
    const [resData, setResData] = useState([]);
    const getCart = () => {
        let type = "web";
        authAxios({
            method: "GET",
            url: `cart/list/${type}`,
        })
            .then((res) => {
                setResData(res.data.data || []);
                // console.log("first", res.data.data);
                setCartnotification(res?.data?.data?.length);
            })
            .catch((error) => {
                common.error(error);
            });
    };

    function closeSideBar() {
        setModalCountry(true);
        setMenu(true);
    }

    const getNotification = () => {
        let notiData = {
            page: 1,
            perPage: 20,
        };
        authAxios({
            method: "POST",
            url: `/notifications/pagin`,
            data: notiData,
        })
            .then((res) => {
                let data = res.data;
                setNotification(data);
            })
            .catch((error) => {
                common.error(error);
            });
    };

    const markAsRead = (id) => {
        authAxios({
            method: "POST",
            url: `/notifications/markAsRead/${id}`,
        })
            .then((res) => {
                getNotification();
            })
            .catch((error) => {
                common.error(error);
            });
    };

    const markAllRead = () => {
        authAxios({
            method: "POST",
            url: `/notifications/markAllRead`,
        })
            .then((res) => {
                getNotification();
            })
            .catch((error) => {
                common.error(error);
            });
    };

    const toggleDropdown = () => setDropdownIsOpen(!dropdownIsOpen);

    function refreshtoken() {
        authAxios({
            method: "GET",
            url: `/customer/tokenRefresh`,
        })
            .then((res) => {
                localStorage.setItem("token", res.data.token);
            })
            .catch((error) => {
                // reactLocalStorage.remove("token");
            });
    }
    const getProfile = () => {
        authAxios({
            method: "GET",
            url: `/customer/myProfile`,
        })
            .then((res) => {
                let getdata = res?.data?.data || {};
                setProfileData(getdata);
            })
            .catch((error) => {
                common.error(error);
            });
    };
    // useEffect(() => {
    //     let intrVal;
    //     if (isLogin) {
    //         intrVal = setInterval(() => {
    //             refreshtoken();
    //         }, 10000);
    //     }
    //     return () => clearInterval(intrVal);
    // }, [isLogin]);

    useEffect(() => {
        if (isLogin) {
            getNotification();
            getProfile();
            getCart();
        }
    }, []);
    useEffect(() => {
        let w = window.innerWidth
        setWidth(w)
    });

    // function googleTranslateElementInit() {
    //   new window.google.translate.TranslateElement(
    //     { pageLanguage: 'hi' },
    //     'google_translate_element'
    //   );
    // }

    // useEffect(() => {
    //   var addScript = document.createElement('script');
    //   addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
    //   document.body.appendChild(addScript);
    //   window.googleTranslateElementInit = googleTranslateElementInit;
    // }, [])

    return (
        <>
            <header className="headerWrapper">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="menusWrapr leftMenu">
                                <ul>
                                    <li onClick={() => setModalCountry(true)}>
                                        <div className="menublock">
                                            <span className="iconsMenu loc">
                                                <img className="img-fluid" src="/assets/images/navlocation.svg" alt="images" />
                                            </span>
                                            {/* {cityIdforMatch == initialData?.city?._id &&
                      (<span className="nameMenu">{initialData?.city?.name || 'City'}</span>)
                      } */}
                                            <span className="nameMenu">{initialData?.city?.name || "City"}</span>
                                            <span className="arrowMenu">
                                                <i className="arrow-down"></i>
                                            </span>
                                        </div>
                                    </li>

                                    <li onClick={() => setModalCountry(true)}>
                                        <div className="menublock">
                                            <span className="iconsMenu">
                                                <img className="img-fluid" src="/assets/images/navpray.svg" alt="images" />
                                            </span>
                                            <span className="nameMenu">{initialData?.preference?.name || "Pandit Preference"}</span>
                                            <span className="arrowMenu">
                                                <i className="arrow-down"></i>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="/blogs">
                                            <span className="iconsMenu">
                                                <img className="img-fluid" src="/assets/images/blog.svg" alt="images" />
                                            </span>
                                            <span className="nameMenu">Blogs</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2">
                            <div className="logoWrap">
                                <Link to="/">
                                    <img src="/assets/images/navsamskara.svg" className="img-fluid" alt="logo" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="menusWrapr rightMenu">
                                <ul>
                                        <li>
                                            <Link to="/how-it-works">
                                                <span className="iconsMenu">
                                                    <img className="img-fluid" src="/assets/images/officebox.svg" alt="images" />
                                                </span>
                                                <span className="nameMenu">How It Works</span>
                                            </Link>
                                        </li>
                                    {/* {width > 991 && (
                                        <li>
                                            <a>
                                                <span className="iconsMenu">
                                                    <img className="img-fluid" src="/assets/images/language.png" alt="images" />
                                                </span>
                                                <Translator />
                                            </a>
                                            
                                        </li>    
                                    )} */}
                                    

                                    {/* <li>
                    <div className="menublock">
                      <span className="iconsMenu">
                        <img className="img-fluid" src="/assets/images/language.png" alt="images" />
                      </span>
                      <span className="nameMenuselect" id="langbox">
                        <div onChange={(e)=> setLanguage(e.target.value)}>
                        </div>
                      </span>
                    </div>
                  </li> */}
                                    {isLogin ? (
                                        <>
                                            <li className="nav-item dropdownWrp">
                                                <Dropdown show={dropdownIsOpen} onToggle={toggleDropdown} align="end">
                                                    <Dropdown.Toggle variant="thm" id="dropdown-basic">
                                                        <i className="fas fa-bell"></i>
                                                        {notificationList.unReadCount > 0 ? <span className="badge bg-danger rounded-circle position-absolute">{notificationList.unReadCount}</span> : ""}
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <>
                                                            <div className="notification_popup_inner">
                                                                {notificationList.unReadCount > 0 ? (
                                                                    <button type="button" onClick={() => markAllRead()} className="btn markAllReadbutton">
                                                                        Mark All As Read
                                                                    </button>
                                                                ) : (
                                                                    ""
                                                                )}

                                                                <div className="notifyInside scroller">
                                                                    {notificationList.unReadCount > 0 ? (
                                                                        <>
                                                                            {notificationList?.result?.map((data, key) => (
                                                                                <>
                                                                                    {data.isRead == false ? (
                                                                                        <div className="accountnotificationcon active" key={key}>
                                                                                            <div className="accountnotipara">
                                                                                                <p>{data?.description}</p>
                                                                                                <button type="button" onClick={() => markAsRead(data?._id)} className="btn mark-read-btn">
                                                                                                    Mark read
                                                                                                </button>
                                                                                            </div>
                                                                                            {data.adminPush && <p className="mb-0">{data?.description}</p>}
                                                                                            {data.adminPush && data.imageUrl && <div className="text-center"><img src={data.imageUrl} style={{height:"170px", objectFit:"contain", borderRadius:'5px'}} className="img-fluid"/></div>}
                                                                                            <div className="accountheaderdatecon">
                                                                                                <Moment calendar={calendarStrings}>{data?.createdAt}</Moment>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    ) : (
                                                                        <NotificationNotFound />
                                                                    )}
                                                                </div>
                                                                <Link to="/notifications">
                                                                    <span onClick={() => toggleDropdown()} className="btn cus_btn custom01 d-block">
                                                                        {" "}
                                                                        View All
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li>
                                                <Link to="/cart">
                                                    <i className="fas fa-shopping-cart"></i>
                                                    <span className="badge bg-danger rounded-circle cartnotification">{Number(cartnotification) > 0 ? cartnotification : ""}</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Dropdown className="userder" show={menuDrp} onToggle={() => setMenuDrp(!menuDrp)} align="end">
                                                    <Dropdown.Toggle variant="thm">
                                                        {/* <i className="fas fa-user"></i> */}
                                                        <span className="iconsMenu">
                                                            <img className="img-fluid" src="/assets/images/navlinkmenu.svg" alt="images" />
                                                        </span>
                                                        <div className="iconsMenu2">
                                                            <span className="parnamstyle">Pranam <br/>{profileData?.firstName} Ji</span>
                                                        </div>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="menuLIstwrp">
                                                            <ul>
                                                                <li onClick={() => setMenuDrp(!menuDrp)}>
                                                                    <Link to="/profile">
                                                                        <i className="icons icon-user"></i>
                                                                        <span>Account</span>
                                                                    </Link>
                                                                </li>
                                                                <li onClick={() => setMenuDrp(!menuDrp)}>
                                                                    <Link to="/mybooking">
                                                                        <i className="icons icon-booking"></i>
                                                                        <span>Bookings</span>
                                                                    </Link>
                                                                </li>
                                                                <li onClick={() => setMenuDrp(!menuDrp)}>
                                                                    <Link to="/helpdesk">
                                                                        <i className="icons icon-help"></i>
                                                                        <span>Help Desk</span>
                                                                    </Link>
                                                                </li>
                                                                <li onClick={() => setMenuDrp(!menuDrp)}>
                                                                    <Link to="/myreferral">
                                                                        <i className="icons icon-earn"></i>
                                                                        <span>My Coupons</span>
                                                                    </Link>
                                                                </li>
                                                                <li onClick={() => setMenuDrp(!menuDrp)}>
                                                                    <a onClick={() => reset()}>
                                                                        <i className="icons icon-sign-out"></i>
                                                                        <span>Sign Out</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </>
                                    ) : (
                                        <li>
                                            <Link to="/signin">
                                                <span className="iconsMenu">
                                                    <img className="img-fluid" src="/assets/images/navlinkmenu.svg" alt="images" />
                                                </span>
                                                <span className="nameMenu">Sign In</span>
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
			

            <div className="navdisplay">
                <div className="container">
                    <Link to="/" className="col-auto me-auto">
                        <img className="homelogo img-fluid" src="/assets/images/navsamskara.svg" alt="image" />
                    </Link>
                    <div className="homesignconWRP">
                        <a onClick={() => setMenu(false)}>
                            <img className="homemenu img-fluid" src="/assets/images/cr-logo.png" alt="image" />
                        </a>
                        {/* {isLogin ? (
              
            ):(
                <Link to="/signin">
                    <span className="iconsMenu">
                      <img className="img-fluid" src="/assets/images/navlinkmenu.png" alt="images" />
                    </span>
                    <span className="nameMenu">Sign In</span>
                </Link>
              )} */}
                        <div className={`menubackdrop ${menu == false ? "active" : ""}`} onClick={() => setMenu(true)}></div>
                        {!menu && (
                            <div>
                                <div className="menusliderwrp">
                                    <div className="menuslidecon">
                                        <div onClick={() => setMenu(true)} className="slidemeniimgcon">
                                            <img src="/assets/images/accountcross.png" alt="image" />
                                        </div>
                                        <ul>
                                            <li onClick={() => closeSideBar()}>
                                                <div className="menublock">
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/navlocation.svg" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">{initialData?.city?.name || "City"}</span>
                                                    <span className="arrowMenu">
                                                        <i className="arrow-down"></i>
                                                    </span>
                                                </div>
                                            </li>
                                            <li onClick={() => closeSideBar()}>
                                                <div className="menublock">
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/navpray.svg" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">{initialData?.preference?.name || "Pandit Preference"}</span>
                                                    <span className="arrowMenu">
                                                        <i className="arrow-down"></i>
                                                    </span>
                                                </div>
                                            </li>
                                            {isLogin && (
                                                <>
                                                    <li>
                                                        <Link to="/cart" onClick={() => setMenu(true)}>
                                                            <i className="fas fa-shopping-cart"></i>
                                                            <span className="nameMenu menu">Cart</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/profile" onClick={() => setMenu(true)}>
                                                            <i className="icons icon-user"></i>
                                                            <span>Account</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/myBooking" onClick={() => setMenu(true)}>
                                                            <i className="icons icon-booking"></i>
                                                            <span>Bookings</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/helpDesk" onClick={() => setMenu(true)}>
                                                            <i className="icons icon-help"></i>
                                                            <span>Help Desk</span>
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/myreferral" onClick={() => setMenu(true)}>
                                                            <i className="icons icon-earn"></i>
                                                            <span>My Coupons</span>
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                            <li>
                                                <Link to="/blogs" onClick={() => setMenu(true)}>
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/blog.svg" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">Blogs</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/how-it-works" onClick={() => setMenu(true)}>
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/officebox.svg" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">How It Works</span>
                                                </Link>
                                            </li>
                                            {/* {width <= 991 && (
                                                <li>
                                                    <a>
                                                        <span className="iconsMenu">
                                                            <img className="img-fluid" src="/assets/images/language.png" alt="images" />
                                                        </span>
                                                        
                                                        <Translator />
                                                    </a>
                                                    
                                                </li>    
                                            )} */}
                                            
                                            <li>
                                                <Link to={'/puja-service'}>
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/kalasha.png" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">Puja Services</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/community-event'}>
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/diversity.png" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">Community Events</span>
                                                    
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/consultancy-service'}>
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/customer-support.png" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">Consulting Services</span>
                                                    
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link to={'/custom/services'}>
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/planning.png" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">Plan Your Events</span>
                                                    
                                                </Link>
                                            </li> */}
                                            <li>
                                                <Link to={'/'}>
                                                    <span className="iconsMenu">
                                                        <img className="img-fluid" src="/assets/images/pagoda.png" alt="images" />
                                                    </span>
                                                    <span className="nameMenu">Temple Services</span>
                                                    
                                                </Link>
                                            </li>

                                            {isLogin ? (
                                                <li>
                                                    <a onClick={() => reset()}>
                                                        <i className="icons icon-sign-out"></i>
                                                        <span>Sign Out</span>
                                                    </a>
                                                </li>
                                            ) : (
                                                <li>
                                                    <Link to="/signin">
                                                        <span className="iconsMenu">
                                                            <img className="img-fluid" src="/assets/images/navlinkmenu.svg" alt="images" />
                                                        </span>
                                                        <span className="nameMenu">Sign In</span>
                                                    </Link>
                                                </li>
                                            )}
                                        </ul>
                                        <div className="d-flex nav-startup-div">
                                            <div className="d-flex mb-2">
                                                <div className="one">
                                                    <img className="img-fluid" src="/assets/images/startup/Logo1.png"/>
                                                </div>
                                                <div className="two">
                                                    <img className="img-fluid" src="/assets/images/startup/DPIIT.svg"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

			<div className="sortMenuswrp ">
				<div className="container ">
					<div className="quikLinkWrp">
						<ul className="justify-content-center">
							<li onClick={()=>navigate('/puja-service')} className="custom-nav-link custom-nav-link-anim">
								<Link to={'/puja-service'}>Puja Services</Link>
							</li>
							<li onClick={()=>navigate('/community-event')} className="custom-nav-link custom-nav-link-anim">
								<Link to={'/community-event'}>Community Events</Link>
							</li>
							<li onClick={()=>navigate('/consultancy-service')} className="custom-nav-link custom-nav-link-anim">
								<Link to={'/consultancy-service'}>Consulting Services</Link>
							</li>
							{/* <li onClick={()=>navigate('/custom/services')} className="custom-nav-link custom-nav-link-anim">
								<Link to={'/custom/services'}>Plan Your Events</Link>
							</li> */}
							<li onClick={()=>navigate('/')} className="custom-nav-link custom-nav-link-anim">
								<Link to={'/'}>Temple Services</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
            <PageSideMenu />
            <CountryModal />
        </>
    );
}
